import React from 'react';
import facebook_logo from "../images/facebook_logo.png";
import instagram_logo from "../images/instagram_logo.png";
import youtube_logo from "../images/youtube_logo.png";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import FaceBookIcon from "../utility/FaceBookIcon";
import InstagramIcon from "../utility/InstagramIcon";
import YouTubeIcon from "../utility/YouTubeIcon";

class Footer extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-6">
                            <div className="row">
                                <div className="col-md-4 modal-link-wrapper">
                            <span className="modal-link"
                                  data-toggle="modal"
                                  data-target="#PrivacyPolicyModal">
                                Політика конфіденційності
                            </span>
                                </div>
                                <div className="col-md-4 modal-link-wrapper">
                            <span className="modal-link"
                                  data-toggle="modal"
                                  data-target="#DisclaimerModal">
                                Відмова від відповідальності
                            </span>
                                </div>
                                <div className="col-md-4 modal-link-wrapper">
                            <span className="modal-link"
                                  data-toggle="modal"
                                  data-target="#MailConsentModal">
                                Згода з разсилкою
                            </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-lg-6">
                            <div className="row">
                                <div className="col-md-7 col-lg-8 social-title">
                                    Ми в соц. мережах:
                                </div>
                                <div className="col-md-5 col-lg-4">
                                    <div className="row justify-content-around social-box">
                                        <div className="social-button facebook-icon">
                                            <a href={process.env.REACT_APP_FACEBOOK_CHANNEL} target="_blank">
                                                <FaceBookIcon />
                                            </a>
                                        </div>
                                        <div className="social-button instagram-icon">
                                            <a href={process.env.REACT_APP_INSTA_CHANNEL} target="_blank">
                                                <InstagramIcon />
                                            </a>
                                        </div>
                                        <div className="social-button youtube-icon">
                                            <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank">
                                                {/*<img src={youtube_logo} alt=""/>*/}
                                                <YouTubeIcon />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="trademark-box">
                        © ФОП Шевченко О.А. 2016-2020
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;