import React from 'react';

class Goals extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Goals">
                <div className="container px-3">
                    <div className="row justify-content-center">

                        <div className="col-md-4 col-lg-3 goal-item">
                            <div className="title">
                                Забезпечимо
                            </div>
                            <div className="body">
                                100% реалізацію Ваших прав, передбачених Законом України «Про захист прав споживачів»
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 goal-item">
                            <div className="title">
                                Налаштуємо
                            </div>
                            <div className="body">
                                Конструктивний діалог з продавцем при виникненні у Вас проблем з товаром або послугою
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 goal-item">
                            <div className="title">
                                Гарантуємо
                            </div>
                            <div className="body">
                                Захист і адекватну юридичну реакцію на порушення Ваших прав
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Goals;